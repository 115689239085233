<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ bannerTitle }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="category-navigate">
          <ul>
            <a
              href="#"
              @click.prevent="selectTag(item.tag_name, true)"
              v-for="(item, index) in tags"
              :key="index"
              ><li
                :class="[
                  'btn btn-primary btn-lg',
                  { active: item.tag_name == tagSelected },
                ]"
              >
                {{ item.tag_name }}
              </li></a
            >
          </ul>
        </div>
      </div>
      <section class="blog-area pb-70">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 col-xl-6 animated fadeInUp"
              v-for="(item, index) in albumList"
              :key="index"
              @click="goAlbum(item.gallery_album_id)"
            >
              <div class="card act-card-hover">
                <div class="row no-gutters">
                  <div class="col-md-5">
                    <img :src="item.path" alt="Image" class="rounded" />
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h5 class="card-title">{{ item.album_name }}</h5>
                      <p class="card-text"></p>
                    </div>
                    <div class="card-footer">
                      <span
                        v-for="(tagItem, tagIndex) in item.tag"
                        :key="tagIndex"
                        class="badge badge-info"
                        ># {{ tagItem.tag_name }}</span
                      >
                      <i class="fas fa-eye">{{ item.clicks }}</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        :class="['row', 'text-center', { 'is-show-next-page': isShowNextPage }]"
      >
        <div class="col-lg-12 read-more">
          <button class="btn btn-warning" @click="getNextAlbumList()">
            {{ $t("pageBtn") }}<i class="fas fa-chevron-right ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
const apiUrl = `${process.env.VUE_APP_API_URL}/gallery`;
export default {
  name: "AlbumBody",
  props: {
    bannerTitle: String,
  },
  data() {
    return {
      albumList: [],
      page: 1,
      isShowNextPage: false,
      tags: [],
      tagSelected: "",
    };
  },
  async mounted() {
    await this.getTag();
    if (this.tags.length != 0) {
      this.selectTag(this.tags[0].tag_name);
    }
  },
  methods: {
    selectTag(tagName, refresh = false) {
      if (refresh) {
        this.page = 1;
      }
      this.tagSelected = tagName;
      this.albumList = [];
      this.getAlbumList();
    },
    goAlbum(gallery_album_id) {
      this.$router.push(`${this.$route.path}/album/${gallery_album_id}`);
    },
    async getTag() {
      const api = `${apiUrl}/tag_list`;
      let postParams = {
        gallery_id: 1,
        page: 1,
        limit: "50",
        order_by: [
          {
            field: "tag_id",
            method: "DESC",
          },
        ],
        filters: {
          //"tag_group" : `${this.title},zh-tw`
          tag_group: `subtitle,${this.$i18n.locale}`,
        },
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await this.axios.post(api, postParams, postConfig).then((response) => {
        this.tags = response.data.list;
      });
    },
    async getAlbumList() {
      const api = `${apiUrl}/album_list`;
      let postParams = {
        gallery_id: 1,
        page: this.page,
        limit: "10",
        language_id: this.$i18n.locale,
        order_by: [
          {
            field: "post_date",
            method: "DESC",
          },
        ],
        filters: {
          tags: [],
        },
        image_width: 250,
      };
      if (this.tagSelected != "") {
        postParams.filters.tags.push(this.tagSelected);
      }
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response.data.list;
        let pageNumber = response["data"]["count"]["all"];
        this.showPageBtnCheck(pageNumber);
        if (this.page == 1) {
          this.albumList = data;
        } else {
          const cardListStash = this.albumList.concat(data);
          this.albumList = cardListStash;
        }

        this.albumList.forEach((element) => {
          if (element.album_name[this.$i18n.apiLang] != undefined) {
            element.album_name = element.album_name[this.$i18n.apiLang];
          }
          let tagList = element.tag;
          let tags = [];
          //處理 Tag lang
          for (let tagIndex in tagList) {
            if (
              tagList[tagIndex].tag_group == `subtitle,${this.$i18n.locale}`
            ) {
              tags.push(tagList[tagIndex]);
            }
          }
          element.tag = tags;
          element.post_date = DateFormat(
            new Date(Date.parse(element.post_date.replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
        });
      });
    },
    async getNextAlbumList() {
      this.page = this.page + 1;
      await this.getAlbumList();
    },
    showPageBtnCheck(pageNumber) {
      this.isShowNextPage = false;
      if (parseInt(pageNumber) / 10 <= this.page) {
        this.isShowNextPage = true;
      }
    },
  },
};
</script>

<style scoped>
.is-show-next-page {
  display: none;
}

.card-footer {
  color: #b5bac1;
}

.card-body {
  min-height: 150px;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.in-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.blog-area {
  margin-top: 30px;
}

.card {
  margin-top: 30px;
}

.act-card-hover {
  border-radius: 8x;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.5s;
}

.act-card-hover:hover {
  transform: scale(1.02, 1.02);
}

.card img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.badge {
  font-weight: 500;
}

.fa-eye {
  padding-top: 5px;
  float: right;
}

.read-more {
  border-top: 1px solid #ced4da;
  padding-top: 25px;
}

.fa-eye::before {
  margin-right: 5px;
}

.badge-info {
  padding: 5px 8px;
  margin-right: 4px;
  font-size: 14px;
}
</style>
