var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.bannerTitle))]),
        _c("p", [
          _vm._v(
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          )
        ])
      ]),
      _c("div", { staticClass: "row justify-content-center mt-5" }, [
        _c("div", { staticClass: "category-navigate" }, [
          _c(
            "ul",
            _vm._l(_vm.tags, function(item, index) {
              return _c(
                "a",
                {
                  key: index,
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.selectTag(item.tag_name, true)
                    }
                  }
                },
                [
                  _c(
                    "li",
                    {
                      class: [
                        "btn btn-primary btn-lg",
                        { active: item.tag_name == _vm.tagSelected }
                      ]
                    },
                    [_vm._v(" " + _vm._s(item.tag_name) + " ")]
                  )
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("section", { staticClass: "blog-area pb-70" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.albumList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "col-lg-6 col-xl-6 animated fadeInUp",
                  on: {
                    click: function($event) {
                      return _vm.goAlbum(item.gallery_album_id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card act-card-hover" }, [
                    _c("div", { staticClass: "row no-gutters" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("img", {
                          staticClass: "rounded",
                          attrs: { src: item.path, alt: "Image" }
                        })
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h5", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.album_name))
                          ]),
                          _c("p", { staticClass: "card-text" })
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-footer" },
                          [
                            _vm._l(item.tag, function(tagItem, tagIndex) {
                              return _c(
                                "span",
                                {
                                  key: tagIndex,
                                  staticClass: "badge badge-info"
                                },
                                [_vm._v("# " + _vm._s(tagItem.tag_name))]
                              )
                            }),
                            _c("i", { staticClass: "fas fa-eye" }, [
                              _vm._v(_vm._s(item.clicks))
                            ])
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "div",
        {
          class: [
            "row",
            "text-center",
            { "is-show-next-page": _vm.isShowNextPage }
          ]
        },
        [
          _c("div", { staticClass: "col-lg-12 read-more" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                on: {
                  click: function($event) {
                    return _vm.getNextAlbumList()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("pageBtn"))),
                _c("i", { staticClass: "fas fa-chevron-right ml-2" })
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }